/* VARIABLES */
.myshoppro-page {
    --mark-font--size: 1rem;
}
.msp-btn--start,
.msp-btn--start * {
    --button-height: 62px;
    --button-spacer: 40px;
    --button-padding: 45px;
    --button-radius: 10px;
    --button-weight: 500;
    --button-color: var(--clr-prm-600);
    --button-bg: var(--clr-white);
    --button-color--hover: var(--clr-prm-600);
    --button-bg--hover: var(--clr-white);
    --button-shadow: none;
    --button-border: transparent;
    font-size: 1.125rem;
    letter-spacing: 0;
}
.pb23-btn.msp-btn--default,
.pb23-btn.msp-btn--default * {
    --button-height: 44px;
    --button-spacer: 10px;
    --button-padding: 24px;
    --button-radius: 10px;
    --button-weight: 500;
    --button-color: var(--clr-prm-600);
    --button-bg: var(--clr-white);
    --button-color--hover: var(--clr-prm-600);
    --button-bg--hover: var(--clr-white);
    --button-shadow: none;
    --button-border: transparent;
}
.pb23-btn.msp-btn--highlight,
.pb23-btn.msp-btn--highlight * {
    --button-height: 44px;
    --button-spacer: 10px;
    --button-padding: 24px;
    --button-radius: 10px;
    --button-weight: 500;
    --button-color: #3176ea;
    --button-bg: var(--clr-white);
    --button-color--hover: #3176ea;
    --button-bg--hover: var(--clr-white);
    --button-shadow: none;
    --button-border: transparent;
}
.msp-table,
.msp-table * {
    --table-clr-base: #EAECF0;
}
.ambient-tag,
.ambient-tag * {
    --icon-color: #f0f0f0;
    --icon-selected: #007BFF;
}

/* Marcador de enviado */
.sent--item {
    min-height: 46px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 50px;
    text-decoration: none;
    font-size: .75rem;
    color: var(--clr-success-400);
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    letter-spacing: 2px;
    line-height: 1.2;
    border: 1px solid var(--clr-success-400);
    border-radius: 10px;
}
.sent--item span {
    color: var(--clr-success-400);
    font-weight: 400;
}

/* Página Geral */
/*.myshoppro-page {
    padding-top: calc(var(--mainmenu-height) + 35px);
}*/
.myshoppro-page h2 {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0 0 18px;
}
.myshoppro-page h2.msp-title {
    margin: 0 0 20px;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}
.myshoppro-page .pb-footer {
    margin-top: 30px;
}

/* Tabelas */
.myshoppro-page .msp-table--container {
    width: 100%;
    position: relative;
    /*overflow-x: auto;*/
    overflow: hidden;
    background: var(--clr-white);
}

.myshoppro-page .msp-table--container:has(> .msp-table.ambients) {
    /* border: 2px solid var(--clr-gray-700); */
    border-radius: 15px;
    margin: 0;
}
.myshoppro-page .msp-table--options {
    position: absolute;
    top: 20px;
    right: 20px;
}
.myshoppro-page .msp-table--options a {
    margin: 0 0 0 20px;
}
.myshoppro-page .msp-table {
    width: 100% !important;
}
.myshoppro-page .msp-table caption {
    padding: 20px 24px;
    border: 1px solid var(--table-clr-base);
    border-bottom: none;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    caption-side: top;
    cursor: grabbing;
}
.myshoppro-page .msp-table--container-sortable {
    /* border: 2px solid var(--clr-gray-700); */
    border-radius: 15px;
    margin: 1rem 0;
    background-color: var(--color-lighter-alt);
    min-height: 100px;
}
.myshoppro-page .msp-table thead tr {
    background: var(--clr-gray-100);
}
.myshoppro-page .msp-table thead th {
    padding: 14px 24px;
    font-size: 0.875rem;
    color: var(--clr-gray-500);
}
.myshoppro-page .msp-table .ui-sortable-handle-sortable
{
    background-color: var(--color-lighter-alt);
    height: 50px;
    border: 1px solid var(--color-lighter-alt);
}
.myshoppro-page .msp-table tbody tr.ui-sortable-helper
{
    background-color: white;
    border: 1px solid black;
    width: 300px;
    border-radius: 10px;

}
.myshoppro-page .msp-table tbody tr td {
    font-size: 0.875rem;
    padding: 22px 24px;
    border-bottom: 1px solid var(--table-clr-base);
 
}
.myshoppro-page .my-shop--cart-edit .msp-table tbody tr td {
    cursor: grabbing;
}
.myshoppro-page .myshop--list {
    margin: 1rem 0;
}
.myshoppro-page .myshop--list a.hoverable-icon img:hover,
.myshoppro-page .myshop--list button.hoverable-icon svg:hover,
.myshoppro-page .my-shop--cart-edit a.hoverable-icon img:hover,
.myshoppro-page .my-shop--cart-edit button.hoverable-icon svg:hover {
    filter: brightness(0);
}
.myshoppro-page .msp-table tbody tr td.msp-table--cell-options {
    padding: 22px 10px;
}
.myshoppro-page .myshop--list .msp-table tbody tr td.actions {
    width: 7%;
    max-width: 50px;
    text-align: center;
}
.myshoppro-page .myshop--list .dataTables_filter ,
.myshoppro-page .myshop--list .dataTables_length {
    display: none;
}
.myshoppro-page .myshop--list .dataTables_filter ,
.myshoppro-page .myshop--list .dataTables_length {
    display: none;
}
.myshoppro-page .myshop--list .dataTables_paginate.paging_simple_numbers * {
    text-decoration: none;
}
.myshoppro-page .myshop--list .dataTables_paginate.paging_simple_numbers li a {
    line-height: 1rem;
    padding: 15px;
    /* border:solid 1px var(--clr-link-400); */
}
.myshoppro-page .myshop--list .dataTables_paginate.paging_simple_numbers ul.pagination li:first-child a{
    line-height: 1rem;
    border-radius: 10px 0 0 10px;
}
.myshoppro-page .myshop--list .dataTables_paginate.paging_simple_numbers ul.pagination li:last-child a{
    line-height: 1rem;
    border-radius: 0 10px 10px 0;
}
.myshoppro-page .msp-table tbody tr td:first-of-type {
    font-weight: 700;
}
.myshoppro-page .msp-table tfoot tr td {
    padding: 20px 24px;
    border: 1px solid var(--table-clr-base);
    border-top: none;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: right !important;
    text-transform: uppercase;
    font-weight: 500;
}
.myshoppro-page .msp-table tfoot tr td,
.myshoppro-page .msp-table tfoot tr td span {
    white-space: nowrap;
}
.pb-myshop-ambients > .msp-table--container{
    overflow: auto;
}
.pb-myshop-ambients .msp-table.ambients > caption, 
.pb-myshop-ambients .msp-table.ambients > tfoot tr {
    background: var(--color-white);
    border: 0;
}

.msp-table .mst-table--title {
    text-align: left !important;
}
.msp-table.ambients tbody tr td:nth-of-type(5),
.msp-table.ambients tbody tr td:nth-of-type(6),
.msp-table.ambients tbody tr td:nth-of-type(7),
.msp-table.ambients tbody tr td:nth-of-type(8),
.myshop--list .msp-table.dataTable tbody tr td:nth-of-type(5) {
    white-space: nowrap;
}
/* Campo de Busca para Tabela */
.myshoppro-page .msp-search-area {
    padding: 10px 20px;
    border: 1px solid var(--clr-gray-150);
    border-radius: 15px;
}
.myshoppro-page .msp-search-area > label {
    width: 100%;
    margin: 0;
}
/* Wrapper se Seção */
.myshoppro-page .msp-wrapper {
    margin: 0 0 40px;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
}
.myshoppro-page .msp-wrapper.form {
    align-items: flex-start;
}
.myshoppro-page .msp-info--add .msp-wrapper  {
    align-items: flex-start;
}
/* Box */
.myshoppro-page [class^="msp-info"].middle {
    padding: 0px 100px;
}
@media (max-width: 1200px) {
    .myshoppro-page [class^="msp-info"].middle {
        padding: 0px 80px;
    }
}
@media (max-width: 769px) {
    .myshoppro-page [class^="msp-info"].middle {
        padding: 0px 40px;
    }
}
@media (max-width: 600px) {
    .myshoppro-page [class^="msp-info"].middle {
        padding: 0px 0px;
    }
}

/* Forms */
.myshoppro-page .msp-wrapper label {
    width: 100%;
    max-width: 362px;
    margin: 0;
}
.myshoppro-page label.msp-field--notes {
    min-height: 100px;
    max-width: 100%;
    width: 100%;
}

.myshoppro-page label.msp-field--name,
.myshoppro-page label.msp-field--vendor,
.myshoppro-page label.msp-field--mail,
.myshoppro-page label.msp-field--client-name,
.myshoppro-page label.msp-field--client-mail,
.myshoppro-page label.msp-field--client-phone,
.myshoppro-page label.msp-field--client-zip,
.myshoppro-page label.msp-field--client-city,
.myshoppro-page label.msp-field--client-cpf,
.myshoppro-page label.msp-field--client-street,
.myshoppro-page label.msp-field--client-number,
.myshoppro-page label.msp-field--client-state {
    max-width: 362px;
}
.myshoppro-page .my-shop--cart-create label.msp-field--vendor select{
    min-height: 42px;
}
.myshoppro-page label.msp-field--search {
    max-width: 520px;
    z-index: 99;
} 
.myshoppro-page label.msp-field--ambient {
    max-width: 236px;
}
.myshoppro-page label.msp-field--qtd {
    max-width: 200px;
}
@media (max-width: 769px) {
    .myshoppro-page label.msp-field--name,
    .myshoppro-page label.msp-field--vendor,
    .myshoppro-page label.msp-field--mail,
    .myshoppro-page label.msp-field--search,
    .myshoppro-page label.msp-field--client-name,
    .myshoppro-page label.msp-field--client-mail,
    .myshoppro-page label.msp-field--client-phone,
    .myshoppro-page label.msp-field--client-zip,
    .myshoppro-page label.msp-field--client-city,
    .myshoppro-page label.msp-field--client-state,
    .myshoppro-page label.msp-field--ambient,
    .myshoppro-page label.msp-field--qtd {
        max-width: 100%;
    }
}
/* Escolha de Ambientes */
.myshoppro-page .msp-info--ambient ul {
    display: grid;
    grid-template-columns: repeat(8,1fr);
    gap: 48px;
    padding: 0;
    list-style: none

}
.myshoppro-page .msp-info--ambient ul li .ambient-tag {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.myshoppro-page .msp-info--ambient ul li .ambient-tag input {
    visibility: hidden;
    position: absolute;
}
.myshoppro-page .msp-info--ambient ul li .ambient-tag:hover {
    cursor: pointer;
}
.myshoppro-page .msp-info--ambient ul li .ambient-tag:before {
    display: none;
}
.myshoppro-page .msp-info--ambient ul li .ambient-tag span {
    order: 2;
    font-weight: 300;
    text-align: center;
    margin: 0;
    padding: 0;
}

.myshoppro-page .msp-info--ambient ul li .ambient-tag span.size {
    order: 2;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin: 0;
    padding: 0;
}

.myshoppro-page .msp-info--ambient ul li .ambient-tag span:before {
    display: none !important;
}
.myshoppro-page .msp-info--ambient ul li .ambient-tag .icon {
    order: 1;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background: var(--icon-color);
    border: 2px solid transparent;
}
.myshoppro-page .msp-info--ambient ul li button.ambient-tag {
    border: 1px solid #49505A !important;
}
.myshoppro-page .msp-info--ambient ul li.ambient-add span {
    width: 100%;
    display: block;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin: 10px 0 0;
    padding: 0;
}
.myshoppro-page .msp-info--ambient ul li .ambient-tag:has(input:checked) .icon {
    border: 2px solid var(--icon-selected);
}
.myshoppro-page .msp-info--ambient ul li .ambient-tag .icon img {
    width: 35%;
    height: 40%;
}
.myshoppro-page .msp-info--ambient ul li button.ambient-tag {
    order: 1;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background: var(--icon-color);
    border: 2px solid transparent;
}
.myshoppro-page .msp-info--ambient ul li button.ambient-tag > img {
    width: 50%;
}
@media (max-width: 1200px) {
    .myshoppro-page .msp-info--ambient ul {
        grid-template-columns: repeat(5,1fr);
        gap: 36px;
    }
}
@media (max-width: 769px) {
    .myshoppro-page .msp-info--ambient ul {
        grid-template-columns: repeat(4,1fr);
        gap: 24px;
    }
}
@media (max-width: 600px) {
    .myshoppro-page .msp-info--ambient ul {
        grid-template-columns: repeat(2,1fr);
        gap: 24px;
    }
}

/* CTA Wrapper */
.myshoppro-page .msp-cta--wrapper {
    /* margin: 40px 0; */
    display: flex; 
    justify-content: center;
    gap: 20px;
}
.myshoppro-page .msp-cta--wrapper.vertical {
    margin: 0;
    flex-direction: column;
    justify-content: flex-start;
}
.my-shop--cart-edit .msp-cta--wrapper.vertical .pb23-btn {
    min-width: 242px;
}
/* Attachment Area */
.msp-attach--area.error {
    width: 100%;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    border: 1px solid var(--clr-error-400);
    border-radius: 15px;
}

/* Attachment Area */
.msp-attach--area.success {
    width: 100%;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    border: 1px solid var(--clr-success-400);
    border-radius: 10px;
}

/* Attachment Area */
.msp-attach--area {
    width: 100%;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    border: 1px solid var(--clr-gray-450);
    border-radius: 15px;
}
.msp-attach--area p {
    line-height: 1.5;
    font-weight: 500;
}
.msp-attach--area p a {
    color: var(--clr-link-400);
}
.my-shop--cart-edit .msp-info--attach,
.my-shop--cart-edit .msp-info--notes {
    margin-top: 40px;
}

#uploadErrorMessage{
    padding-top: 10px;
    text-align: right;
    color: var(--clr-error-400);
    font-weight: bold;
}

#uploadOKMessage{
    padding-top: 10px;
    text-align: right;
    color: var(--clr-success-400);
    font-weight: bold;
}

.my-shop--cart-edit .msp-info--actions {
    margin-top: 40px;
}
.my-shop--cart-edit .msp-info--actions .msp-wrapper {
    justify-content: center;
}
.my-shop--cart-edit .total-wrapper .pb23-btn {
    padding: 10px;
    margin: 0 30px 0 0;
}
.my-shop--cart-edit .msp-wrapper.cart-header label {
    max-width: 300px;
}
.my-shop--cart-edit .total-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}
/* MOdal */
.pb-myshop-info-modal {
    max-width: 800px;
}
.pb-myshop-viewcart-modal.pb23-modal .msp-field--notes span {
    text-align: left;
}
/* Fix Notification */
.myshoppro-page .pb-notification .notification-container .content .title {
    font-size: 1.125rem;
}

.jsSearchCart{
    /* text-transform: uppercase; */
    font-weight: 400 !important;
    color: #6c757d !important;
}
.msp-search-area label:before {
    background: none !important;
    border: none !important;
}
.msp-search-area label input {
    padding: 0 20px !important;
}